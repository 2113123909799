import React from "react";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta, Header } from "../docs";
import { Ol, Paragraph, TextContainer, Ul } from "../../lib/src";

export const Meta: DocPageMeta = {
	category: "Developing",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection title="Contributing" subTitle="How to contribute to the Fancy Design System" />
			<ContentSection>
				<TextContainer article>
					<Header.H2>Before you start</Header.H2>
					<Paragraph>
						If you intend to build a new component, change a public API, make design improvements,
						or any other non-trivial changes, we recommend getting in touch with us in{" "}
						<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">#ux_fancy</a> to clear UX
						patterns and ensure visions are aligned. This lets us all discuss and reach an agreement
						on the proposal before you put in significant time and effort.
					</Paragraph>

					<Header.H3>Establish shared value</Header.H3>
					<Paragraph>
						<strong>The first step of any contribution</strong> &mdash; design, code, or
						documentation &mdash; is establishing the shared value for the proposed feature or
						enhancement. To ensure the design system provides the highest shared value,{" "}
						<strong>contributions to Fancy should be relevant to two or more product teams</strong>.
					</Paragraph>
					<Paragraph>
						Identifying other products or use cases that may benefit from your proposed contribution
						not only helps define requirements, but also establishes key partners to collaborate
						with.
					</Paragraph>
					<Header.H3>What to do if you're unable to establish shared value</Header.H3>
					<Paragraph>
						If you're unable to establish shared value for your contribution across two or more
						products, consider how to use existing Fancy components and patterns to build the
						feature you need within your own product.
					</Paragraph>
					<Paragraph>
						If you feel that your contribution still makes sense, you can always start a
						conversation with the Fancy team in{" "}
						<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">#ux_fancy</a> or{" "}
						<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">#dev_fancy</a>.
					</Paragraph>
					<Paragraph>
						In order to keep our design system and processes light and flexible, communication on
						desired changes and visions is important, since the Design System impacts all teams and
						parts of the product. We want to ensure a coherent and awesome user experience across
						all products and development teams.
					</Paragraph>
					<Header.H2>When should you talk with the Fancy team?</Header.H2>
					<Paragraph>
						While{" "}
						<strong>
							Product Designers at Siteimprove are expected to partner with the Fancy team
						</strong>{" "}
						for their work, we recommend that all POs and engineers engage with us early, and often!
						Either by writing in{" "}
						<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">#ux_fancy</a> or{" "}
						<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">#dev_fancy</a> or directly to
						Fancy team members. We offer a collaborative space in which to incubate your ideas. Here
						are some checkpoints that are great times to reach out to our team:
					</Paragraph>
					<Header.H3>As a Product Designer</Header.H3>
					<Ul
						items={[
							<>
								<strong>At the start of a project/early wireframe mockups.</strong> Reach out to us
								with heads up on projects, deadlines for delivery, and any components or
								compositions that you're confident will be necessary.
							</>,
							<>
								<strong>At the start of medium or high fidelity design.</strong> This is a great
								time to share your specific needs with the Fancy team. You may also begin to receive
								feedback on accessibility or pattern usage at this stage.
							</>,
							<>
								<strong>Before user testing, UX reviews or launch.</strong> Before you share your
								ideas with customers or get a sign off on the final design, drop by for a deep dive
								on component and composition usage, final polish and other design considerations.
							</>,
						]}
					/>
					<Header.H3>As a Developer</Header.H3>
					<Ul
						items={[
							<>
								<strong>When making (breaking) changes to Fancy.</strong> Before making (breaking)
								changes to Fancy Lab or Lib, we recommend you reach out to us. This enables us to
								collaborate with you on your proposal and discuss important points, before you put
								in significant time and effort. This early feedback loop is especially helpful when
								proposing breaking changes.
							</>,
							<>
								<strong>
									When encountering design specifications that Fancy components don't support.
								</strong>{" "}
								This will often be an indicator that there is misalignment within the UX team on
								what cases a component or pattern should be used for. In other cases, the
								restriction is unintended and we can discuss the best course of action for resolving
								the issue. In either case, it's great feedback for the Fancy team and valuable input
								for future component requirements.
							</>,
						]}
					/>
					<Header.H2>What kind of support can you expect from the Fancy team?</Header.H2>
					<Paragraph>
						We're excited to partner with you on building better products. Towards this, you can
						expect the following levels of support from the team:
					</Paragraph>
					<Ul
						items={[
							"Request components or patterns that you may need for your customer experiences.",
							"Get feedback on the usage of components and compositions in your mocks and/or UIs.",
							"Get information about building more accessible UIs, and any common pitfalls that one might typically encounter.",
							"Get support on hierarchy, weight, and spacing in your UIs.",
							"Get answers to clarifying questions about Design Systems concepts.",
							"Verify if your UIs are aligned with the content, UX, and accessibility guidelines.",
							"Get constructive feedback that helps your UI follow the design system and accessibility conventions that are expected in Siteimprove.",
						]}
					/>
					<Paragraph>
						It's important to note that the Design Systems team does not directly talk to customers,
						and <strong>is not a replacement for research and customer understanding</strong>. While
						we're happy to provide feedback on the product decisions made, we recommend testing with
						customers or customer proxies to better understand if we're solving for their needs.
					</Paragraph>
					<Header.H2>Contribution process</Header.H2>
					<Paragraph>
						To streamline contributions to the Fancy Design System and improve transparency, we have
						a formal contribution process. This process ensures that all requests are tracked,
						reviewed, and prioritized effectively.
					</Paragraph>
					<Header.H3>How to contribute</Header.H3>
					<Ol
						items={[
							<>
								<strong>Slack post</strong>: Post your question, issue or request in the{" "}
								<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">#dev_fancy</a> channel.
							</>,
							<>
								<strong>Fancy Dev Review meeting</strong>: We will host the weekly Fancy Dev Review
								meeting on Wednesdays at 2 PM, where we will discuss new posts in the{" "}
								<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">#dev_fancy</a> channel.
							</>,
							<>
								<strong>Ticket creation</strong>: We will create a Jira ticket for any valid issues
								or approved requests. See what have been created and track your progress in the Jira
								project belonging to{" "}
								<a href="https://siteimprove-wgs.atlassian.net/browse/PSE">Platform Services</a>.
							</>,
							<>
								<strong>Refinement and planning</strong>: We wll bring tickets to the team’s regular
								refinement meeting and get approval to implement the changes in this or a later
								sprint.
							</>,
							<>
								<strong>Implementation and release</strong>: We will post release notes in the{" "}
								<a href="https://siteimprove.slack.com/archives/CCH0J3U4E">#dev_fancy</a> channel on
								a regular basis when Fancy is updated.
							</>,
						]}
					></Ol>
					<Paragraph>This process provides us with the following benefits:</Paragraph>
					<Ul
						items={[
							<>
								<strong>Improved visibility</strong>: All requests and changes are tracked via Jira
								tickets, ensuring transparency and accountability.
							</>,
							<>
								<strong>Effective prioritization</strong>: The weekly Fancy Dev Review meeting
								allows for collaborative prioritization of contributions.
							</>,
							<>
								<strong>Streamlined workflow</strong>: A clear process ensures efficient handling of
								requests from submission to implementation.
							</>,
							<>
								<strong>Better communication</strong>: Regular updates and changelogs keep everyone
								informed about the evolution of the design system.
							</>,
						]}
					></Ul>
				</TextContainer>
			</ContentSection>
		</>
	);
};
